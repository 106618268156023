/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import { forwardRef, useMemo, useRef } from "react";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { Spacing } from "@/tokens/spacing";

import { useCombinedRefs } from "@/util/hooks/ref_hooks";
import { convertToRem } from "@/util/ui_util";

interface ProductFeatureTimerProps {
    enabled: boolean;
    isActive: boolean;
    onComplete: () => void;
}

export const ProductFeatureTimer = forwardRef<
    HTMLDivElement,
    ProductFeatureTimerProps
>((props, ref) => {
    /**
     * Styles
     */
    const containerStyles = css({
        marginBottom: Spacing["spacing-4"],
        transformOrigin: "left",
    });

    const backgroundStyles = useMemo(
        () =>
            css({
                backgroundColor: Colors["lighten-10"],
                borderRadius: BorderRadiuses.borderRound,
                padding: convertToRem(2),
            }),
        [],
    );

    const trackStyles = useMemo(
        () =>
            css({
                borderRadius: BorderRadiuses.borderRound,
                height: convertToRem(4),
                overflow: "hidden",
            }),
        [],
    );

    const fillStyles = useMemo(
        () =>
            css({
                backgroundColor: Colors["white"],
                borderRadius: BorderRadiuses.borderRound,
                height: "100%",
                width: "100%",
            }),
        [],
    );

    const fill = useRef<HTMLDivElement | null>(null);

    useGSAP(() => {
        if (!props.enabled || !props.isActive) {
            return;
        }

        gsap.from(fill.current, {
            duration: 10,
            ease: "linear",
            onComplete: props.onComplete,
            scrollTrigger: {
                toggleActions: "play pause play pause",
                trigger: fill.current,
            },
            xPercent: -100,
        });
    }, [props.enabled, props.onComplete, props.isActive]);

    return (
        <div css={containerStyles} ref={ref}>
            <div css={backgroundStyles} role="progressbar">
                <div css={trackStyles}>
                    <div css={fillStyles} ref={fill} />
                </div>
            </div>
        </div>
    );
});

ProductFeatureTimer.displayName = "ProductFeatureTimer";

import { Expo, gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useEffect, useRef, useState } from "react";

import { TypedArrayRefObject, TypedRefObject } from "@/types/interactivity";

import { Colors } from "@/tokens/color";

gsap.registerPlugin(ScrollTrigger);

export function useBillboardScrollToAnimation(
    containerRef: TypedRefObject,
    currentSlide: number,
) {
    /**
     * State Management
     */
    const [didInit, setDidInit] = useState(false);
    const [isTransitioning, setIsTransitioning] = useState(false);

    /**
     * Effects
     */
    useEffect(() => {
        const scrollEnd = () => {
            setIsTransitioning(false);
        };

        const onResize = () => {
            const isInview = () => {
                if (!containerRef.current) return false;
                const bbox = containerRef.current?.getBoundingClientRect();
                return (
                    (bbox.top >= 0 && bbox.top <= window.innerHeight) ||
                    (bbox.bottom >= 0 && bbox.bottom <= window.innerHeight)
                );
            };

            if (didInit && isInview()) {
                containerRef.current?.children[currentSlide].scrollIntoView({
                    behavior: "instant",
                    block: "nearest",
                    inline: "center",
                });
                setIsTransitioning(true);
            }
        };

        containerRef.current?.addEventListener("scrollend", scrollEnd);
        window.addEventListener("resize", onResize);

        setDidInit(true);

        return () => {
            containerRef.current?.removeEventListener("scrollend", scrollEnd);
            window.removeEventListener("resize", onResize);
        };
    }, [
        containerRef,
        isTransitioning,
        setIsTransitioning,
        currentSlide,
        didInit,
    ]);

    useEffect(() => {
        const isInview = () => {
            if (!containerRef.current) return false;
            const bbox = containerRef.current?.getBoundingClientRect();
            return (
                (bbox.top >= 0 && bbox.top <= window.innerHeight) ||
                (bbox.bottom >= 0 && bbox.bottom <= window.innerHeight)
            );
        };

        if (didInit && isInview()) {
            containerRef.current?.children[currentSlide].scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "center",
            });
            setIsTransitioning(true);
        }
    }, [containerRef, currentSlide, didInit]);
}

export function useSubheadlineAnimation(
    textRef: TypedRefObject,
    numDemos: number,
    activeDemo: number,
) {
    /**
     * State Management
     */
    const [didInit, setDidInit] = useState(false);

    /**
     * Effects
     */
    useEffect(() => {
        gsap.set(textRef.current, {
            onComplete: () => {
                setDidInit(true);
            },
            opacity: 1,
        });
    }, [textRef]);

    useEffect(() => {
        const onResize = () => {
            textRef.current?.scrollTo({
                behavior: "instant",
                left: (textRef.current.scrollWidth / numDemos) * activeDemo,
            });
        };
        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [textRef, numDemos, activeDemo]);

    useEffect(() => {
        if (didInit) {
            const _duration = 0.5;
            const _ease = Expo.easeInOut;
            gsap.to(textRef.current, {
                duration: _duration,
                ease: _ease,
                onComplete: () => {
                    textRef.current?.scrollTo({
                        behavior: "instant",
                        left:
                            (textRef.current.scrollWidth / numDemos) *
                            activeDemo,
                    });
                },
                opacity: 0,
            });

            gsap.to(textRef.current, {
                delay: _duration,
                duration: _duration,
                ease: _ease,
                opacity: 1,
            });
        }
    }, [textRef, activeDemo, didInit, numDemos]);
}

export function useAmbientListScrollAnimation(
    scrollParentOverflowRef: TypedRefObject,
) {
    /**
     * Globals
     */
    const _duration = 20;
    const _ease = "linear";

    /**
     * Refs
     */
    const animationRef = useRef<gsap.core.Timeline | null>(null);

    animationRef.current = gsap.timeline({
        force3D: true,
        /*
         * Note: found the "onReverseComplete" solution here:
         * https://gsap.com/community/forums/topic/28078-reverse-repeat/
         */
        onReverseComplete: function () {
            this.totalTime(this.duration() * 100 + this.rawTime()); // include rawTime() to compensate for the tiny offset between frames (zero time drift)
        },
        repeat: -1,
    });

    /* Initialize the scroll animation */
    useEffect(() => {
        if (!animationRef.current) return;

        const action = animationRef.current.fromTo(
            scrollParentOverflowRef.current,
            {
                y: "0",
            },
            {
                duration: _duration,
                ease: _ease,
                y: "-50%",
            },
        );

        ScrollTrigger.create({
            onUpdate(self) {
                const velocity = self.getVelocity();

                const scrollingDown = self.direction === 1;

                const timeScale =
                    3 + (scrollingDown ? velocity : -velocity) / 50;

                gsap.timeline()
                    .to(action, {
                        duration: 0.1,
                        timeScale: scrollingDown ? timeScale : -timeScale,
                    })
                    .to(action, {
                        duration: 0.1,
                        ease: Expo.easeOut,
                        timeScale: scrollingDown ? 1 : -1,
                    });
            },
        });
    }, [scrollParentOverflowRef]);
}

export function useAmbientListItemScrollAnimation(
    listItemRef: TypedArrayRefObject,
    scrollParentOverflowRef: TypedRefObject,
) {
    useEffect(() => {
        if (!listItemRef.current || !scrollParentOverflowRef.current) return;

        /*
         *I know this looks nuts but I'm:
         *1. Getting the percentage of how tall the listItem is in relation to the parent.
         *2. Then I'm getting the remaining percentage
         *3. Then splitting that up in half to get the vertical margin. (e.g. -45.123%)
         */

        const listItemHeightPercentage =
            (listItemRef.current[0]!.getBoundingClientRect().height /
                scrollParentOverflowRef.current.getBoundingClientRect()
                    .height) *
            100;

        const remainingHeightPercentage = 100 - listItemHeightPercentage;

        const splitRootMargin = `-${remainingHeightPercentage / 2}% 0px`;

        const yellowObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio >= 0.5) {
                        gsap.to(entry.target, {
                            color: Colors["yellow-40"],
                        });
                    } else {
                        gsap.to(entry.target, {
                            color: Colors["lighten-40"],
                        });
                    }
                });
            },
            {
                root: scrollParentOverflowRef.current,
                rootMargin: splitRootMargin,
                threshold: 0.5,
            },
        );

        const lightenObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio >= 0.5) {
                        gsap.to(entry.target, {
                            color: Colors["lighten-40"],
                        });
                    } else {
                        gsap.to(entry.target, {
                            color: Colors["lighten-30"],
                        });
                    }
                });
            },
            {
                root: scrollParentOverflowRef.current,
                rootMargin: `-20% 0px -20% 0px`,
                threshold: 0.5,
            },
        );

        if (listItemRef.current) {
            listItemRef.current.forEach((item) => {
                yellowObserver.observe(item as Element);
                lightenObserver.observe(item as Element);
            });
        }

        return () => {
            if (listItemRef.current) {
                listItemRef.current.forEach((item) => {
                    yellowObserver.unobserve(item as Element);
                    lightenObserver.unobserve(item as Element);
                });
            }
        };
    }, [listItemRef, scrollParentOverflowRef]);
}

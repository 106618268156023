/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiTestimonial } from "@/types/strapi";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { Spacing } from "@/tokens/spacing";

import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Picture } from "@/ui/atoms/picture";
import { SubgridContainer } from "@/ui/atoms/subgrid_container";
import { Text } from "@/ui/atoms/text";

import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { buildStylesByBreakpoint } from "@/util/style_util";
import { convertToRem } from "@/util/ui_util";

interface ClassicTestimonialProps
    extends Omit<StrapiTestimonial, "__component"> {
    className?: SerializedStyles;
}

export const ClassicTestimonial: FunctionComponent<ClassicTestimonialProps> = (
    props,
) => {
    /**
     * Theme
     */
    const theme = useTypedTheme();

    /**
     * Styles
     */
    const containerStyles = css({
        alignItems: "flex-start",
    });

    const headshotStyles = css({
        aspectRatio: "1 / 1",
        background: Colors[theme.backgrounds.backgroundSecondary],
        borderRadius: BorderRadiuses.borderMedium,
        width: convertToRem(64),
    });

    const quoteContainerStyles = buildStylesByBreakpoint("rowGap", {
        extraSmall: Spacing["spacing-3"],
        small: Spacing["spacing-4"],
        large: Spacing["spacing-6"],
    });

    const attributionTextContainerStyles = css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
    });

    const logoContainerStyles = css({
        display: "flex",
        justifyContent: "center",
    });

    const logoStyles = css(
        {
            opacity: 0.5, // Maps to `lighten-50` since logo is off-white by default
            width: "auto",
        },
        buildStylesByBreakpoint("height", {
            // Todo (kyleribant): develop sizing algorithm for any dimension
            extraSmall: convertToRem(24),
            medium: convertToRem(32),
        }),
        buildStylesByBreakpoint("marginBottom", {
            extraSmall: Spacing["spacing-4"],
            small: Spacing["spacing-5"],
            large: Spacing["spacing-6"],
        }),
    );

    const attributionContainerStyles = css(
        { alignItems: "center", display: "flex", flexDirection: "column" },
        buildStylesByBreakpoint("rowGap", {
            extraSmall: Spacing["spacing-2"],
            small: Spacing["spacing-3"],
        }),
        buildStylesByBreakpoint("marginTop", {
            extraSmall: Spacing["spacing-8"],
            extraLarge: Spacing["spacing-10"],
        }),
    );

    /**
     * Rendering
     */
    const renderAttribution = () => {
        const { Company, Headshot, Name, Role } = props.Author!;

        return (
            <GridColumn className={attributionContainerStyles}>
                {Headshot && (
                    <Picture
                        alternativeText={`Headshot of ${Name}`}
                        aspectRatio="1 / 1"
                        className={headshotStyles}
                        height={Headshot.height}
                        url={Headshot.url}
                        width={Headshot.width}
                    />
                )}

                <div css={attributionTextContainerStyles}>
                    <Text
                        fontSize="Attribution"
                        fontWeight="medium"
                        textAlign="center"
                        themeKey="textEmphasis"
                    >
                        {Name}
                    </Text>

                    <Text
                        fontSize="Attribution"
                        textAlign="center"
                        themeKey="textSecondary"
                    >
                        {Company ? `${Role} at ${Company.Name}` : Role}
                    </Text>
                </div>
            </GridColumn>
        );
    };

    const renderLogo = () => {
        const { Logo, Name } = props.Author!.Company!;

        return (
            <GridColumn className={logoContainerStyles}>
                <Picture
                    alternativeText={`Logo for ${Name}`}
                    className={logoStyles}
                    height={Logo.height}
                    url={Logo.url}
                    width={Logo.width}
                />
            </GridColumn>
        );
    };

    const renderQuote = () => {
        return (
            <SubgridContainer
                className={quoteContainerStyles}
                columnSpan={{
                    extraSmall: "full",
                    medium: 10,
                    large: 8,
                }}
                columnStart={{
                    extraSmall: 1,
                    medium: 2,
                    large: 3,
                }}
            >
                {props.Headline ? (
                    <>
                        <Text
                            columnSpan="full"
                            columnStart={1}
                            fontSize="HeadlineHero"
                            tag="h2"
                            textAlign="center"
                        >
                            {props.Headline}
                        </Text>

                        <Text
                            className={css({
                                textWrap: "pretty",
                            })}
                            columnSpan={{
                                extraSmall: "full",
                                medium: 8,
                                large: 6,
                            }}
                            columnStart={{
                                extraSmall: 1,
                                medium: 2,
                            }}
                            fontSize="Subheadline"
                            tag="blockquote"
                            textAlign="center"
                        >
                            {props.Quote}
                        </Text>
                    </>
                ) : (
                    <Text
                        columnSpan="full"
                        columnStart={1}
                        fontSize="Headline1"
                        shouldBalanceWrap={true}
                        tag="blockquote"
                        textAlign="center"
                        themeKey="textEmphasis"
                    >
                        {props.Quote}
                    </Text>
                )}
            </SubgridContainer>
        );
    };

    return (
        <GridContainer className={containerStyles} legacyGrid={false}>
            {props.Author &&
                props.Author.Company &&
                props.Author.Company.Logo &&
                props.Author.Company.Has_Logo_Rights &&
                renderLogo()}

            {renderQuote()}

            {props.Author && renderAttribution()}
        </GridContainer>
    );
};

/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent } from "react";

import {
    SupportedOperatingSystem,
    SupportedOperatingSystemKeys,
} from "@/types/data";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { TransitionEasings, TransitionSpeeds } from "@/tokens/motion";
import { Spacing } from "@/tokens/spacing";

import { Link } from "@/ui/atoms/link";
import { OperatingSystemIcon } from "@/ui/atoms/operating_system_icon";
import { Text } from "@/ui/atoms/text";

import { useGlobalsContext } from "@/util/context/globals_context";
import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { backgroundBlur, buildStylesByBreakpoint } from "@/util/style_util";
import { TrackEvent } from "@/util/tokens/track_event_util";
import { convertToRem } from "@/util/ui_util";

interface OperatingSystemBannerProps {
    className?: SerializedStyles;
}

export const OperatingSystemBanner: FunctionComponent<
    OperatingSystemBannerProps
> = (props) => {
    /**
     * Globals
     */
    const theme = useTypedTheme();

    /**
     * Context
     */
    const { downloads } = useGlobalsContext();

    /**
     * Styles
     */
    const containerStyles = css(
        {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            rowGap: Spacing["spacing-2"],
        },
        /**
         * These are the original gap values, but seem tight. Wait until icons
         * buildStylesByBreakpoint("rowGap", {
         *    extraSmall: Spacing["spacing-1"],
         *    extraLarge: Spacing["spacing-2"],
         * }),
         */

        props.className,
    );

    const osListStyles = css(
        {
            alignItems: "stretch",
            display: "flex",
            flexDirection: "row",
        },
        buildStylesByBreakpoint("columnGap", {
            extraSmall: Spacing["spacing-1"],
            medium: Spacing["spacing-half"],
        }),
    );

    const osListItemLinkStyles = css(
        {
            alignItems: "center",
            background: "transparent",
            borderRadius: BorderRadiuses.borderSmall,
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "row",
            height: "100%",
            textDecoration: "none",
            transition: `background ${TransitionSpeeds.fast}ms ${TransitionEasings.easeInOut}`,
        },
        {
            "&:active": {
                background: Colors["lighten-10"],
            },
            "&:hover": {
                background: Colors["lighten-5"],
            },
            "&:hover, &:active": {
                backdropFilter: backgroundBlur("blurSmall"),
            },
        },
        buildStylesByBreakpoint("columnGap", {
            extraSmall: Spacing["spacing-2"],
            medium: Spacing["spacing-3"],
        }),
        buildStylesByBreakpoint("padding", {
            extraSmall: `${Spacing["spacing-1"]} ${Spacing["spacing-1-half"]}`,
            medium: `${Spacing["spacing-1-half"]} ${Spacing["spacing-2-half"]}`,
        }),
    );

    const flagStyles = css({
        backdropFilter: backgroundBlur("blurSmall"),
        background: Colors[theme.backgrounds.backgroundTertiary],
        borderRadius: BorderRadiuses.borderRound,
        padding: `${Spacing["spacing-1"]} ${Spacing["spacing-1-half"]}`,
    });

    const osListItemTextStyles = css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        rowGap: Spacing["spacing-half"],
    });

    const osIconStyles = buildStylesByBreakpoint("width", {
        extraSmall: convertToRem(24),
        small: convertToRem(28),
        medium: convertToRem(32),
    });

    /**
     * Rendering
     */
    const renderOsIcon = (osName: string, isReleased: boolean) => {
        const formattedOsName =
            osName.toLowerCase() as SupportedOperatingSystem;

        const typedOsName: SupportedOperatingSystem =
            SupportedOperatingSystemKeys.includes(formattedOsName)
                ? formattedOsName
                : "mac";

        return (
            <OperatingSystemIcon
                className={osIconStyles}
                color={isReleased ? theme.ui.uiActive : theme.ui.uiInactive}
                slug={typedOsName}
            />
        );
    };

    const renderOsList = () => {
        const osListItems = downloads.map((download) => {
            const { Is_Released } = download;
            const isWindows = download.OS_Name === "Windows";

            return (
                <li key={`os-banner::os::${download.OS_Name}`}>
                    <Link
                        className={osListItemLinkStyles}
                        href={isWindows ? "windows-terminal" : "download"}
                        track={
                            isWindows
                                ? TrackEvent.WINDOWS_WAITLIST_LINK
                                : TrackEvent.ALL_DOWNLOADS_LINK
                        }
                    >
                        {renderOsIcon(download.OS_Name, Is_Released)}

                        <div css={osListItemTextStyles}>
                            <Text
                                fontSize="Micro"
                                themeKey={
                                    Is_Released
                                        ? "textPrimary"
                                        : "textSecondary"
                                }
                            >
                                {download.OS_Name}
                            </Text>

                            {!Is_Released && (
                                <Text
                                    className={flagStyles}
                                    fontSize="TEXT_0625"
                                    fontWeight="medium"
                                    labelLineHeight={true}
                                    themeKey="textSecondary"
                                >
                                    Coming soon
                                </Text>
                            )}
                        </div>
                    </Link>
                </li>
            );
        });

        return <ul css={osListStyles}>{osListItems}</ul>;
    };

    return (
        <div css={containerStyles}>
            <Text
                fontSize="Micro"
                fontWeight="medium"
                textAlign="center"
                themeKey="headlineSecondary"
            >
                Available on
            </Text>

            {renderOsList()}
        </div>
    );
};

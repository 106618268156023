/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import type {
    GetStaticPropsContext,
    InferGetStaticPropsType,
    NextPage,
    PreviewData,
} from "next";
import { ParsedUrlQuery } from "querystring";

import { spacingSets } from "@/tokens/configs/spacing_config";

import { Text } from "@/ui/atoms/text";
import { AmbientList } from "@/ui/organisms/ambient_list";
import { BigTextHero } from "@/ui/organisms/big_text_hero";
import { BillboardCarousel } from "@/ui/organisms/billboard_carousel";
import { ClassicTestimonial } from "@/ui/organisms/classic_testimonial";
import { PrivacyList } from "@/ui/organisms/privacy_list";
import { ProductFeature } from "@/ui/organisms/product_feature";
import { SpecSheet } from "@/ui/organisms/spec_sheet";
import { TestimonialMosaic } from "@/ui/organisms/testimonial_mosaic";
import { VideoBlock } from "@/ui/organisms/video_block";

import { generatePageLevelProps } from "@/util/data_util";
import { generatePreFooterData } from "@/util/strapi_data_util";
import { getGlobals, getHomePage } from "@/util/strapi_util";
import { buildStylesByBreakpoint } from "@/util/style_util";

export const getStaticProps = async ({
    draftMode,
}: GetStaticPropsContext<ParsedUrlQuery, PreviewData>) => {
    const slug = "home";

    const globalsData = await getGlobals(draftMode);
    const pageData = await getHomePage(draftMode);

    return {
        props: {
            ...generatePageLevelProps({ draftMode, slug }),
            ...generatePreFooterData(
                globalsData,
                globalsData.globals.PreFooter,
                true,
                pageData.Theme.Footer_Media,
            ),
            globalsData,
            legacyGrid: false,
            pageData,
            renderDemo: true,
            renderNewNavigationBar: true,
            renderNotifications: true,
            renderPostFooter: true,
        },
    };
};

type PageProps = InferGetStaticPropsType<typeof getStaticProps>;

const Page: NextPage<PageProps> = (props) => {
    /**
     * Styles
     */
    const pageStyles = css(
        { display: "flex", flexDirection: "column" },
        buildStylesByBreakpoint("rowGap", spacingSets.DefaultSectionGap),
    );

    const videoBlockStyles = buildStylesByBreakpoint("display", {
        extraSmall: "flex",
        medium: "none",
    });

    /**
     * Elements
     */
    const renderPlaceholderString = (componentName: string, key: string) => {
        return <Text key={key}>{`The future home of ${componentName}`}</Text>;
    };

    const renderPageBody = () => {
        return props.pageData.Page_Body.map((pageBodyItem, index) => {
            const key = `home-page::body-item::${pageBodyItem.__component}-${index}`;

            switch (pageBodyItem.__component) {
                case "sections.section-billboard-carousel": {
                    return <BillboardCarousel {...pageBodyItem} key={key} />;
                }
                case "sections.section-product-feature": {
                    return <ProductFeature {...pageBodyItem} key={key} />;
                }
                case "sections.section-spec-sheet": {
                    return <SpecSheet {...pageBodyItem} key={key} />;
                }
                case "sections.section-ambient-list":
                    return <AmbientList {...pageBodyItem} key={key} />;
                case "sections.section-privacy": {
                    return <PrivacyList {...pageBodyItem} key={key} />;
                }
                case "sections.section-testimonial": {
                    return <ClassicTestimonial {...pageBodyItem} key={key} />;
                }
                case "sections.section-mosaic": {
                    return <TestimonialMosaic {...pageBodyItem} key={key} />;
                }

                default: {
                    return renderPlaceholderString(
                        pageBodyItem.__component,
                        key,
                    );
                }
            }
        });
    };

    return (
        <div css={pageStyles}>
            <BigTextHero
                {...props.pageData.Hero}
                backgroundMedia={props.pageData.Theme?.Hero_Media}
            />

            {/* Inline video for XS + S breakpoints */}
            <VideoBlock className={videoBlockStyles} />

            {renderPageBody()}
        </div>
    );
};

export default Page;

/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent, useRef } from "react";

import { StrapiIconGraphic } from "@/types/strapi";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";

import { Icon } from "@/ui/atoms/icon";
import { Media } from "@/ui/atoms/media";

import { useIconWidth } from "@/util/hooks/icon_hooks";
import { useTypedTheme } from "@/util/hooks/theme_hooks";

interface IconGraphicProps extends StrapiIconGraphic {
    className?: SerializedStyles;
}

export const IconGraphic: FunctionComponent<IconGraphicProps> = ({
    ...props
}) => {
    /**
     * Globals
     */
    const aspectRatio = props.Media.Aspect_Ratio ?? "Square_1x1";

    const theme = useTypedTheme();

    /**
     * Refs
     */
    const containerRef = useRef(null);
    const iconRef = useRef(null);

    /**
     * Hooks
     */
    const iconSize = useIconWidth(containerRef);

    /**
     * Styles
     */
    const containerStyles = css(
        {
            backgroundColor: Colors[theme.backgrounds.backgroundSecondary], // Temporary background color until we get assets in
            borderRadius: BorderRadiuses.borderLarge,
            display: "flex",
            overflow: "hidden",
            position: "relative",
        },
        props.className,
    );

    const iconContainerStyles = css({
        alignItems: "center",
        bottom: 0,
        display: "flex",
        height: "100%",
        justifyContent: "center",
        left: 0,
        position: "absolute",
        right: 0,
        top: 0,
        width: "100%",
        zIndex: 5,
    });

    /**
     * Rendering
     */
    const renderIcon = () => {
        return (
            <div css={iconContainerStyles} ref={iconRef}>
                <Icon
                    color="lighten-90"
                    size={iconSize}
                    slug={props.Icon}
                    weight="thin"
                />
            </div>
        );
    };

    return (
        <div css={containerStyles} ref={containerRef}>
            {renderIcon()}

            <Media
                {...props.Media}
                Aspect_Ratio={aspectRatio}
                shouldParallax={true}
            />
        </div>
    );
};

/* eslint-disable sort-keys-custom-order/object-keys */

/* eslint-disable react-hooks/exhaustive-deps */
import * as _ from "lodash-es";
import { useState } from "react";

import { TypedRefObject } from "@/types/interactivity";

import { useIsomorphicLayoutEffect } from "./effect_hooks";

export const useIconWidth = (containerRef: TypedRefObject) => {
    /**
     * Globals
     */
    const iconWidthMapping: { [key: number]: number } = {
        400: 96,
        700: 112,
        1208: 160,
        1512: 192,
    };

    /**
     * State Management
     */
    const [iconSize, setIconSize] = useState<number>(0);

    /**
     * Util
     */
    const updateIconSize = () => {
        if (!containerRef.current) return;

        const divWidth = containerRef.current.offsetWidth;

        const sortedKeys = Object.keys(iconWidthMapping)
            .map(Number)
            .sort((a, b) => a - b);

        const closestMatch = sortedKeys.find((maxWidth) => divWidth < maxWidth);

        if (closestMatch !== undefined) {
            setIconSize(iconWidthMapping[closestMatch]);
        } else {
            // If no match is found, return the value for the largest div size
            setIconSize(iconWidthMapping[sortedKeys[sortedKeys.length - 1]]);
        }
    };

    /**
     * Effects
     */
    useIsomorphicLayoutEffect(() => {
        const debouncedUpdate = _.debounce(updateIconSize, 150);

        updateIconSize();

        window.addEventListener("resize", debouncedUpdate);

        return () => {
            window.removeEventListener("resize", debouncedUpdate);
            debouncedUpdate.cancel();
        };
    }, []);

    return iconSize;
};

import gsap, { Expo } from "gsap";
import _ from "lodash";
import { useEffect, useState } from "react";

import { TypedArrayRefObject, TypedRefObject } from "@/types/interactivity";

import { Spacing } from "@/tokens/spacing";

import { getCardTransform } from "@/ui/atoms/product_feature_media_card";
import { ProductFeaturePanelRef } from "@/ui/organisms/product_feature_panel";

import { useGlobalsContext } from "@/util/context/globals_context";
import { useIsomorphicLayoutEffect } from "@/util/hooks/effect_hooks";

/**
 * Animates the different parts of the feature panel
 * depending on which index is active
 */
export function useProductFeaturePanelExpansionAnimation(
    panelsRef: TypedArrayRefObject<ProductFeaturePanelRef>,
    activeIndex: number,
) {
    /**
     * Variables
     */
    const marginBottom = Spacing["spacing-4"];
    const ease = Expo.easeInOut;

    /**
     * State Management
     */
    const [didInit, setDidInit] = useState(false);

    /**
     * Effects
     */
    useIsomorphicLayoutEffect(() => {
        if (panelsRef.current) {
            const firstPanel = panelsRef.current[0];

            const remainingPanels = panelsRef.current.slice(1);

            if (firstPanel) {
                gsap.set(firstPanel.backgroundRef.current, {
                    autoAlpha: 1,
                    scale: 1,
                });

                gsap.set(firstPanel.progressBarRef.current, {
                    autoAlpha: 1,
                    height: "auto",
                    marginBottom,
                });

                gsap.set(firstPanel.detailsRef.current, {
                    autoAlpha: 1,
                    height: "auto",
                    onComplete: () => {
                        setDidInit(true);
                    },
                });
            }

            if (remainingPanels) {
                remainingPanels.forEach((remainingPanel) => {
                    if (remainingPanel) {
                        gsap.set(remainingPanel.backgroundRef.current, {
                            autoAlpha: 0,
                            scale: 0.9,
                        });
                        gsap.set(remainingPanel.progressBarRef.current, {
                            autoAlpha: 0,
                            height: 0,
                            marginBottom: 0,
                        });
                        gsap.set(remainingPanel.detailsRef.current, {
                            autoAlpha: 0,
                            height: 0,
                        });
                    }
                });
            }
        }
    }, []);

    useEffect(() => {
        if (didInit && panelsRef.current) {
            const duration = 0.75;
            const activePanel = panelsRef.current[activeIndex];

            const inactivePanels = _.filter(
                panelsRef.current,
                (_, i) => i !== activeIndex,
            );

            if (activePanel) {
                gsap.to(activePanel.backgroundRef.current, {
                    autoAlpha: 1,
                    duration,
                    ease,
                    scale: 1,
                });
                gsap.to(activePanel.detailsRef.current, {
                    autoAlpha: 1,
                    duration,
                    ease,
                    height: "auto",
                });
                gsap.to(activePanel.progressBarRef.current, {
                    autoAlpha: 1,
                    duration,
                    ease,
                    height: "auto",
                    marginBottom,
                });
            }

            if (inactivePanels) {
                inactivePanels.forEach((inactivePanel) => {
                    if (inactivePanel) {
                        gsap.to(inactivePanel.backgroundRef.current, {
                            autoAlpha: 0,
                            duration,
                            ease,
                            scale: 0.9,
                        });
                        gsap.to(inactivePanel.progressBarRef.current, {
                            autoAlpha: 0,
                            duration,
                            ease,
                            height: 0,
                            marginBottom: 0,
                        });
                        gsap.to(inactivePanel.detailsRef.current, {
                            autoAlpha: 0,
                            duration,
                            ease,
                            height: 0,
                        });
                    }
                });
            }
        }
    }, [didInit, activeIndex]);
}

/**
 * Collapses, expands, and swaps the order of
 * the media card stack depending on offset change
 */
export function useDesktopMediaCardExpansionAnimation(
    cardRef: TypedRefObject,
    cardHeight: number,
    offset: number,
) {
    /**
     * State
     */
    const [didInit, setDidInit] = useState(false);

    /**
     * Context
     */
    const { browser } = useGlobalsContext();

    /**
     * Effects
     */
    useEffect(() => {
        /**
         * We do opacity-based animations for Safari
         * because it doesn't handle order correctly
         */
        if (browser === "safari") {
            gsap.set(cardRef.current, {
                autoAlpha: offset === 0 ? 1 : 0,
                onComplete: () => {
                    setDidInit(true);
                },
            });

            return;
        }

        if (cardHeight > 0) {
            gsap.set(cardRef.current, {
                autoAlpha: 1,
                onComplete: () => {
                    setDidInit(true);
                },
                order: offset * -1,
                transform: getCardTransform(cardHeight, offset, 14),
            });
        }
    }, [cardHeight]);

    useEffect(() => {
        if (didInit) {
            const ease = Expo.easeOut;

            const duration = 0.75;
            const opacityAnimation = 0.15;

            const timeline = gsap.timeline();

            /**
             * Safari only toggles animation due to
             * issues rendering order and transform
             */
            if (browser === "safari") {
                if (offset === 0) {
                    timeline.to(cardRef.current, {
                        duration: 0,
                        order: offset * -1,
                    });

                    timeline.to(cardRef.current, {
                        autoAlpha: 1,
                        duration: opacityAnimation,
                    });
                }

                if (offset !== 0) {
                    timeline.to(cardRef.current, {
                        autoAlpha: 0,
                        duration: opacityAnimation,
                    });

                    timeline.to(cardRef.current, {
                        duration: 0,
                        order: offset * -1,
                    });
                }

                return;
            } else {
                timeline.to(cardRef.current, {
                    duration: (duration - opacityAnimation) / 2,
                    ease,
                    transform: getCardTransform(cardHeight, 0, 14),
                });
                if (offset !== 0) {
                    timeline.to(cardRef.current, {
                        autoAlpha: 0,
                        duration: opacityAnimation,
                    });
                }
                if (offset === 0) {
                    timeline.to(cardRef.current, {
                        duration: opacityAnimation,
                    });
                }
                timeline.to(cardRef.current, {
                    duration: 0,
                    order: offset * -1,
                });
                timeline.to(
                    cardRef.current,
                    {
                        autoAlpha: 1,
                        duration: (duration - opacityAnimation) / 2,
                        ease,
                        transform: getCardTransform(cardHeight, offset, 14),
                    },
                    "<",
                );
            }
        }
    }, [offset]);
}

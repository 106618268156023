/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Colors } from "@/tokens/color";
import { HalfColumnGaps } from "@/tokens/spacing";

import { Divider } from "@/ui/atoms/divider";

import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { buildStylesByBreakpoint } from "@/util/style_util";

interface VerticalDividersProps {
    children: React.ReactNode;
}
export const VerticalDividers = ({ children }: VerticalDividersProps) => {
    /**
     * Theme
     */
    const theme = useTypedTheme();

    /**
     * Styles
     */
    const wrapperStyle = css({
        position: "relative",
        width: "100%",
    });

    const containerStyle = css(
        {
            boxSizing: "border-box",
            display: "flex",
            height: "100%",
            justifyContent: "space-between",
            position: "absolute",
            width: "100%",
        },
        buildStylesByBreakpoint("paddingLeft", HalfColumnGaps),
        buildStylesByBreakpoint("paddingRight", HalfColumnGaps),
    );

    const gradientLine = css({
        background: `linear-gradient(0deg, ${Colors["transparent"]} 0%, ${Colors[theme.ui.uiBorder]} 10%, ${Colors[theme.ui.uiBorder]} 90%, ${Colors["transparent"]} 100%)`,
    });

    const dashedLine = css({
        background: `linear-gradient(0deg, transparent 50%, ${Colors[theme.backgrounds.backgroundPrimary]} 50%), linear-gradient(0deg, ${Colors["transparent"]} 0%, ${Colors[theme.ui.uiBorder]} 10%, ${Colors[theme.ui.uiBorder]} 90%, ${Colors["transparent"]} 100%)`,
        backgroundSize: "1px 24px, 1px 100%",
    });

    const dividerStyles = css({
        display: "flex",
        flex: "1 1 100%",
        justifyContent: "center",
    });

    const startDividerStyles = css({
        display: "flex",
        flex: "1 1 50%",
        justifyContent: "flex-start",
    });

    const endDividerStyles = css({
        display: "flex",
        flex: "1 1 50%",
        justifyContent: "flex-end",
    });

    /**
     * Rendering
     */
    return (
        <div css={wrapperStyle}>
            <div css={containerStyle}>
                <div css={startDividerStyles}>
                    <Divider className={gradientLine} orientation="vertical" />
                </div>

                <div css={dividerStyles}>
                    <Divider className={gradientLine} orientation="vertical" />
                </div>

                <div css={dividerStyles}>
                    <Divider className={dashedLine} orientation="vertical" />

                    <Divider
                        className={css(gradientLine, {
                            marginLeft: "12px",
                            marginRight: "12px",
                        })}
                        orientation="vertical"
                    />

                    <Divider className={dashedLine} orientation="vertical" />
                </div>

                <div css={dividerStyles}>
                    <Divider className={gradientLine} orientation="vertical" />
                </div>

                <div css={endDividerStyles}>
                    <Divider className={gradientLine} orientation="vertical" />
                </div>
            </div>

            {children}
        </div>
    );
};

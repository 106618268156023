/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { FunctionComponent } from "react";

import { StrapiPrivacy, StrapiPrivacyList } from "@/types/strapi";

import { spacingSets } from "@/tokens/configs/spacing_config";
import { ColumnGaps } from "@/tokens/spacing";
import { Spacing } from "@/tokens/spacing";

import { Divider } from "@/ui/atoms/divider";
import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Link } from "@/ui/atoms/link";
import { SubgridContainer } from "@/ui/atoms/subgrid_container";
import { Text } from "@/ui/atoms/text";
import { IconGraphic } from "@/ui/molecules/icon_graphic";
import { RichText } from "@/ui/molecules/rich_text";
import { TextHeader } from "@/ui/molecules/text_header";

import {
    buildObjectStylesByBreakpoint,
    buildStylesByBreakpoint,
} from "@/util/style_util";

export const PrivacyList: FunctionComponent<StrapiPrivacy> = (props) => {
    /**
     * Styles
     */
    const rowStyles = css(
        buildStylesByBreakpoint("columnGap", ColumnGaps),
        buildObjectStylesByBreakpoint({
            small: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
            },
        }),
    );

    const rowItemStyles = css({ flex: "1 1 0" });

    const dividerStyles = css({ flex: "1 0 100%" });

    const subHeadlineContainerStyles = css(
        rowItemStyles,
        buildStylesByBreakpoint(
            "marginTop",
            spacingSets.PrivacyListSubheadlineContainer,
        ),
    );

    /**
     * Rendering
     */
    const renderSubList = (_detail: StrapiPrivacyList) => (
        <>
            {_detail.List_Introduction && (
                <Text
                    fontSize="Small"
                    fontWeight="medium"
                    marginTop="PrivacyListSubheadline"
                    tag="h4"
                    themeKey="headlinePrimary"
                >
                    {_detail.List_Introduction}
                </Text>
            )}

            {_detail.List && (
                <RichText config="PrivacyListSmall">{_detail.List}</RichText>
            )}
        </>
    );

    const renderPrivacyRow = (_detail: StrapiPrivacyList) => (
        <GridColumn
            className={rowStyles}
            marginBottom="PrivacyListRowBottom"
            tag="li"
        >
            <Divider className={dividerStyles} />

            <Text
                className={rowItemStyles}
                fontSize="Subheadline"
                marginTop="PrivacyListRowTop"
                tag="h3"
            >
                {_detail.Headline}
            </Text>

            <GridColumn className={subHeadlineContainerStyles}>
                <RichText config="PrivacyList">{_detail.Subheadline}</RichText>

                {(_detail.List || _detail.List_Introduction) &&
                    renderSubList(_detail)}
            </GridColumn>
        </GridColumn>
    );

    const renderPrivacyTable = () => (
        <SubgridContainer
            className={css({
                alignContent: "flex-start",
                alignItems: "flex-start",
            })}
            columnSpan={{ extraSmall: "full", medium: 7, large: 5 }}
            columnStart={{ extraSmall: 1, medium: 6, large: 8 }}
            marginTop="PrivacyListMargin"
            tag="ul"
        >
            {props.Details?.map((_detail, _index) => (
                <React.Fragment key={`privacy-list::list::${_index}`}>
                    {renderPrivacyRow(_detail)}
                </React.Fragment>
            ))}

            {props.Header.Link && (
                <GridColumn tag="li">
                    <Divider className={dividerStyles} />

                    <Link
                        fontSize="Default"
                        href={props.Header.Link.URL}
                        marginTop="PrivacyListExploreLink"
                        renderAsBlock={true}
                    >
                        {props.Header.Link.Text}
                    </Link>
                </GridColumn>
            )}
        </SubgridContainer>
    );

    return (
        <GridContainer legacyGrid={false}>
            <TextHeader
                {...props.Header}
                eyebrowColor="blue-40"
                headlineSize="HeadlineHero"
                Link={undefined}
            />

            <GridColumn
                columnSpan={{
                    extraSmall: "full",
                    medium: 4,
                    large: 6,
                }}
                marginTop="PrivacyListMargin"
            >
                <IconGraphic {...props.Graphic} />
            </GridColumn>

            {renderPrivacyTable()}
        </GridContainer>
    );
};

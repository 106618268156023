/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FunctionComponent, useRef } from "react";

import { StrapiAmbientList } from "@/types/strapi";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { ColumnGaps, Spacing } from "@/tokens/spacing";

import { Button } from "@/ui/atoms/button";
import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Picture } from "@/ui/atoms/picture";
import { SubgridContainer } from "@/ui/atoms/subgrid_container";
import { Text } from "@/ui/atoms/text";
import { TextHeader } from "@/ui/molecules/text_header";

import {
    useAmbientListItemScrollAnimation,
    useAmbientListScrollAnimation,
} from "@/util/animation_hooks/homepage_animations";
import { useArrayRef } from "@/util/hooks/ref_hooks";
import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { backgroundBlur, buildStylesByBreakpoint } from "@/util/style_util";

export const AmbientList: FunctionComponent<StrapiAmbientList> = (props) => {
    /**
     * Data
     */
    const benefitsArray = [
        ...props.Benefits.split("\n"),
        ...props.Benefits.split("\n"),
    ];

    /**
     * Globals
     */
    const theme = useTypedTheme();

    /**
     * Refs
     */
    const scrollParentOverflowRef = useRef(null);
    const scrollOverflowRef = useRef(null);
    const [listItemsRef, setListItemsRef] = useArrayRef<HTMLLIElement>();

    /**
     * Hooks
     */
    useAmbientListScrollAnimation(scrollOverflowRef);
    useAmbientListItemScrollAnimation(listItemsRef, scrollParentOverflowRef);

    /**
     * Styles
     */
    const containerStyles = css(
        {
            backgroundColor: Colors[theme.backgrounds.backgroundSecondary],
            borderRadius: BorderRadiuses.borderLarge,
            position: "relative",
        },
        buildStylesByBreakpoint("padding", {
            extraSmall: Spacing["spacing-2"],
            small: Spacing["spacing-2-half"],
            medium: Spacing["spacing-3"],
            large: Spacing["spacing-4"],
        }),
        buildStylesByBreakpoint("gap", ColumnGaps),
    );

    const headlineContainerStyles = css(
        {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            position: "relative",
            zIndex: 1,
        },
        buildStylesByBreakpoint("paddingLeft", {
            extraSmall: Spacing["spacing-2"],
            small: Spacing["spacing-2-half"],
            medium: Spacing["spacing-0"],
        }),
        buildStylesByBreakpoint("paddingRight", {
            extraSmall: Spacing["spacing-2"],
            small: Spacing["spacing-2-half"],
            medium: Spacing["spacing-0"],
        }),
        buildStylesByBreakpoint("paddingTop", {
            extraSmall: Spacing["spacing-4"],
            small: Spacing["spacing-2-half"],
            medium: Spacing["spacing-0"],
        }),
    );

    const backgroundStyles = css({
        zIndex: 0,
    });

    const ambientListContainerStyles = css({
        backdropFilter: backgroundBlur("blurMedium"),
        backgroundColor: Colors[theme.backgrounds.backgroundTertiaryInverted],
        borderRadius: BorderRadiuses.borderLarge,
        position: "relative",
        zIndex: 1,
    });

    const ambientListStyles = css(
        {
            boxSizing: "border-box",
            height: "100%",
            maxWidth: "100%",
            overflow: "hidden",
            position: "relative",
        },
        buildStylesByBreakpoint("aspectRatio", {
            extraSmall: "4/3",
            medium: "3/2",
        }),
        buildStylesByBreakpoint("paddingInline", {
            extraSmall: Spacing["spacing-4"],
            large: Spacing["spacing-6"],
            extraLarge: Spacing["spacing-8"],
        }),
    );

    const ambientListContentStyles = css(
        {
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            height: "max-content",
        },
        buildStylesByBreakpoint("gap", {
            extraSmall: Spacing["spacing-2"],
            medium: Spacing["spacing-3"],
            large: Spacing["spacing-4"],
            extraLarge: Spacing["spacing-5"],
        }),
        buildStylesByBreakpoint("paddingBottom", {
            extraSmall: Spacing["spacing-2"],
            medium: Spacing["spacing-3"],
            large: Spacing["spacing-4"],
            extraLarge: Spacing["spacing-5"],
        }),
    );

    const buttonStyles = css({
        width: "fit-content",
    });

    /**
     * Rendering
     */
    const renderBackgroundImage = () => {
        return (
            <Picture
                alternativeText="Background gradient for product animation"
                aspectRatio="stretch"
                className={backgroundStyles}
                height={props.Background_Media.height}
                priority={true}
                shouldParallax={true}
                sizeConfiguration="fullWidth"
                url={props.Background_Media.url}
                width={props.Background_Media.width}
            />
        );
    };

    const renderAmbientList = () => {
        return (
            <GridColumn
                className={ambientListContainerStyles}
                columnSpan={{
                    extraSmall: 6,
                    medium: 6,
                    large: 5,
                }}
                columnStart={{
                    extraSmall: 1,
                    medium: 7,
                    large: 6,
                }}
                ref={scrollParentOverflowRef}
            >
                <div css={ambientListStyles}>
                    <ul css={ambientListContentStyles} ref={scrollOverflowRef}>
                        {benefitsArray.map((item, index) => {
                            const key = `home-page::body-item::${item}-${index}`;
                            return (
                                <li key={key}>
                                    <Text
                                        color="lighten-40"
                                        fontSize="ScrollableList"
                                        ref={setListItemsRef}
                                    >
                                        {item}
                                    </Text>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </GridColumn>
        );
    };

    return (
        <GridContainer legacyGrid={false}>
            <SubgridContainer
                className={containerStyles}
                columnSpan={{
                    extraSmall: "full",
                    large: 10,
                }}
                columnStart={{
                    extraSmall: 1,
                    large: 2,
                }}
            >
                <GridColumn
                    className={headlineContainerStyles}
                    columnSpan={{
                        extraSmall: 6,
                        medium: 6,
                        large: 4,
                    }}
                >
                    <TextHeader
                        {...props.Header}
                        eyebrowColor="yellow-40"
                        Link={undefined}
                    />

                    {props.Header.Link && (
                        <Button
                            className={buttonStyles}
                            fontSize="CtaDefault"
                            href={props.Header.Link.URL}
                            marginTop="TextHeaderCta"
                        >
                            {props.Header.Link.Text}
                        </Button>
                    )}
                </GridColumn>

                {renderAmbientList()}

                {renderBackgroundImage()}
            </SubgridContainer>
        </GridContainer>
    );
};

/** @jsxImportSource @emotion/react */
import { SerializedStyles } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiBigTextHero, StrapiMedia } from "@/types/strapi";

import { spacingSets } from "@/tokens/configs/spacing_config";

import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { SubgridContainer } from "@/ui/atoms/subgrid_container";
import { Text } from "@/ui/atoms/text";
import { CtaGroup } from "@/ui/molecules/cta_group";
import { HeroDemo } from "@/ui/molecules/hero_product_demo";
import { RichText } from "@/ui/molecules/rich_text";

import { buildStylesByBreakpoint } from "@/util/style_util";

interface BigTextHeroProps extends StrapiBigTextHero {
    backgroundMedia: StrapiMedia;
    className?: SerializedStyles;
}

export const BigTextHero: FunctionComponent<BigTextHeroProps> = (props) => {
    /**
     * Styles
     */
    const containerStyles = buildStylesByBreakpoint(
        "rowGap",
        spacingSets.DefaultPageHeroRowGap,
    );

    const subheadlineStyles = buildStylesByBreakpoint(
        "marginTop",
        spacingSets.TextHeaderSubheadline,
    );

    /**
     * Rendering
     */
    const renderHeroText = () => {
        return (
            <SubgridContainer>
                <GridColumn
                    columnSpan={{
                        extraSmall: "full",
                        medium: 8,
                    }}
                >
                    {props.Eyebrow && (
                        <Text
                            fontSize="HeadlineHeroXl"
                            marginBottom="TextHeaderEyebrow"
                            tag="h1"
                            themeKey="headlineSecondary"
                        >
                            {props.Eyebrow}
                        </Text>
                    )}

                    <Text
                        fontSize="HeadlineHeroXl"
                        tag={props.Eyebrow ? "p" : "h1"}
                        themeKey="headlinePrimary"
                    >
                        {props.Headline}
                    </Text>
                </GridColumn>

                <GridColumn
                    columnSpan={{
                        extraSmall: "full",
                        medium: 7,
                        large: 5,
                    }}
                >
                    <RichText
                        className={subheadlineStyles}
                        config="UiSubheadline"
                    >
                        {props.Subheadline}
                    </RichText>
                </GridColumn>
            </SubgridContainer>
        );
    };

    return (
        <GridContainer
            className={containerStyles}
            legacyGrid={false}
            marginTop="DefaultPageHeroTop"
        >
            {renderHeroText()}

            {props.CTA && (
                <GridColumn>
                    <CtaGroup
                        alignment="start"
                        ctaData={props.CTA}
                        primaryFontSize="CtaHero"
                        secondaryFontSize="Micro"
                    />
                </GridColumn>
            )}

            <HeroDemo
                backgroundMedia={props.backgroundMedia}
                priority={true}
                src={props.Demo.Asset.url}
            />
        </GridContainer>
    );
};

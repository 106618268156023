/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { useGSAP } from "@gsap/react";
import gsap, { Expo } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import Head from "next/head";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useRive, useStateMachineInput } from "rive-react";

import { BorderRadiuses } from "@/tokens/border";
import { ImageAspectRatioString } from "@/tokens/media";

import { useIsomorphicLayoutEffect } from "@/util/hooks/effect_hooks";
import { backgroundBlur } from "@/util/style_util";

interface RiveAnimationProps {
    aspectRatio: ImageAspectRatioString;
    autoplay?: boolean;
    className?: SerializedStyles;
    currentSlide?: number;
    pauseOnViewportExit?: boolean;
    playOnViewportEntry?: boolean;
    priority?: boolean;
    setCurrentSlide?: (state: number) => void;
    src: string;
    stateMachineName?: string;
    triggers?: string[];
}

export const RiveAnimation: FunctionComponent<RiveAnimationProps> = ({
    autoplay = true,
    pauseOnViewportExit = true,
    playOnViewportEntry = true,
    priority = false,
    ...props
}) => {
    /**
     * Refs
     */
    const containerRef = useRef(null);

    /**
     * State
     */
    const [didComponentInit, setDidComponentInit] = useState(false);
    const [didAnimationLoad, setDidAnimationLoad] = useState(false);

    /**
     * Effect Hooks
     */
    useGSAP(() => {
        if (didAnimationLoad) {
            ScrollTrigger.create({
                onEnter: () => {
                    if (autoplay && playOnViewportEntry) {
                        rive?.play();
                    }
                },
                onEnterBack: () => {
                    if (autoplay && playOnViewportEntry) {
                        rive?.play();
                    }
                },
                onLeave: () => {
                    if (autoplay && pauseOnViewportExit) {
                        rive?.pause();
                    }
                },
                onLeaveBack: () => {
                    if (autoplay && pauseOnViewportExit) {
                        rive?.pause();
                    }
                },
                trigger: containerRef.current,
            });
        }
    }, [playOnViewportEntry, pauseOnViewportExit, didAnimationLoad]);

    /**
     * Animation
     */
    const { rive, RiveComponent } = useRive({
        autoplay: false,
        onLoad: () => {
            setDidAnimationLoad(true);
        },
        onStateChange: (state) => {
            if (props.setCurrentSlide && state?.data) {
                const data = state.data as string[];
                const slideNum = parseInt(data[0].replace("Timeline ", ""));
                props.setCurrentSlide(slideNum - 1);
            }
        },
        src: props.src,
        stateMachines: props.stateMachineName
            ? [props.stateMachineName]
            : undefined,
    });

    /* eslint-disable react-hooks/rules-of-hooks */
    const triggers =
        props.triggers && props.stateMachineName
            ? props.triggers.map((trigger) => {
                  return useStateMachineInput(
                      rive,
                      props.stateMachineName,
                      trigger,
                  );
              })
            : undefined;
    /* eslint-enable react-hooks/rules-of-hooks */

    /**
     * Effect when clicking label
     */
    useEffect(() => {
        if (triggers && props.currentSlide !== undefined) {
            triggers[props.currentSlide]?.fire();
        }
    }, [props.currentSlide, triggers]);

    useIsomorphicLayoutEffect(() => {
        if (!didComponentInit)
            gsap.set(containerRef.current, {
                autoAlpha: 0,
                onComplete: () => {
                    setDidComponentInit(true);
                },
                scale: 0.9,
            });
    }, []);

    useEffect(() => {
        if (didComponentInit && didAnimationLoad) {
            gsap.to(containerRef.current, {
                autoAlpha: 1,
                duration: 0.5,
                ease: Expo.easeInOut,
                onComplete: () => {
                    if (autoplay && !playOnViewportEntry) {
                        rive?.play();
                    }
                },
                scale: 1,
            });
        }
    }, [
        didComponentInit,
        didAnimationLoad,
        autoplay,
        rive,
        playOnViewportEntry,
    ]);

    /**
     * Styles
     */
    const containerStyles = css(
        {
            aspectRatio: props.aspectRatio,
            backdropFilter: backgroundBlur("blurMedium"),
            borderRadius: BorderRadiuses.borderLarge,
            opacity: 0,
            overflow: "hidden",
            position: "relative",
            visibility: "hidden",
            zIndex: 5,
        },
        props.className,
    );

    const riveComponentStyles = css({
        height: "100%",
        width: "100%",
    });

    /**
     * Rendering
     */
    return (
        <>
            {/* Conditionally preload the asset */}
            {priority && (
                <Head>
                    <link as="document" href={props.src} rel="prefetch" />
                </Head>
            )}

            <div css={containerStyles} ref={containerRef}>
                <RiveComponent css={riveComponentStyles} />
            </div>
        </>
    );
};

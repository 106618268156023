/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import * as _ from "lodash-es";
import { useRef, useState } from "react";

import { StrapiProductFeature } from "@/types/strapi";

import { Colors } from "@/tokens/color";
import { Spacing } from "@/tokens/spacing";

import { Picture } from "@/ui/atoms/picture";

import { useMediaPanningAnimation } from "@/util/animation_hooks/media_animations";
import { useDesktopMediaCardExpansionAnimation } from "@/util/animation_hooks/product_feature_animations";
import { useGlobalsContext } from "@/util/context/globals_context";
import { useIsomorphicLayoutEffect } from "@/util/hooks/effect_hooks";
import { backgroundBlur, buildStylesByBreakpoint } from "@/util/style_util";
import { convertToRem } from "@/util/ui_util";

interface ProductFeatureMediaCardProps {
    feature: StrapiProductFeature["Features"][number];
    offset: number;
}

export function ProductFeatureMediaCard(props: ProductFeatureMediaCardProps) {
    /**
     * Globals
     */
    const { feature, offset } = props;

    /**
     * Refs
     */
    const cardRef = useRef<HTMLDivElement>(null);
    const assetRef = useRef(null);

    /**
     * Context
     */
    const { browser } = useGlobalsContext();

    /**
     * State
     */
    const [cardHeight, setCardHeight] = useState(0);

    /**
     * Animations
     */
    useIsomorphicLayoutEffect(() => {
        /**
         * Effect to update card height
         */
        if (cardRef.current) {
            setCardHeight(cardRef.current.offsetHeight);
        }
    }, [cardRef.current?.offsetHeight]);

    useDesktopMediaCardExpansionAnimation(cardRef, cardHeight, offset);

    useMediaPanningAnimation(cardRef, assetRef);

    /**
     * Styles
     */
    const cardStyles = css(
        {
            alignItems: "center",
            display: "grid",
            gridColumn: "1",
            gridRow: "1",
            order: -offset,
            position: "relative",
            /**
             * We do not transform if Safari since it has issues
             * with transform and order.
             */
            transform:
                browser !== "safari"
                    ? getCardTransform(cardHeight, offset, 14)
                    : undefined,
            transformOrigin: "top center",
        },
        buildStylesByBreakpoint("paddingInline", {
            medium: Spacing["spacing-6"],
            large: Spacing["spacing-8"],
            extraLarge: Spacing["spacing-10"],
        }),
    );

    const backgroundPictureStyles = css({
        inset: 0,
        position: "absolute",
    });

    return (
        <div css={cardStyles} ref={cardRef}>
            <Picture
                {...feature.Demo_Background}
                aspectRatio="stretch"
                className={backgroundPictureStyles}
                shouldParallax={true}
                sizeConfiguration="fullWidth"
            />

            <div
                css={css({
                    zIndex: 10,
                })}
                ref={assetRef}
            >
                <Picture
                    {...feature.Demo.Asset}
                    pictureClassName={css({
                        backdropFilter: backgroundBlur("blurMedium"),
                    })}
                />
            </div>
        </div>
    );
}

export function getCardTransform(
    cardHeight: number,
    offset: number,
    space: number,
): string {
    if (offset === 0) {
        return "scale(1) translateY(0)";
    }
    const scale = _.round(1 - (offset * space * 2) / cardHeight, 4);
    const translateY = convertToRem(-offset * space);
    return `scale(${scale}) translateY(${translateY})`;
}

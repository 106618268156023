/** @jsxImportSource @emotion/react */
import { SerializedStyles } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiMediaType, StrapiPrimitiveMedia } from "@/types/strapi";

import { BorderRadius } from "@/tokens/border";

import { Picture } from "@/ui/atoms/picture";
import { Text } from "@/ui/atoms/text";

import { detectMediaType } from "@/util/data_util";
import { formatAspectRatio } from "@/util/tokens/layout_util";

interface MediaProps extends StrapiPrimitiveMedia {
    borderRadius?: BorderRadius;
    className?: SerializedStyles;
    shouldParallax?: boolean;
}

export const Media: FunctionComponent<MediaProps> = ({
    shouldParallax = false,
    ...props
}) => {
    const mediaType = detectMediaType(props.Media);

    /**
     * Rendering
     */
    return mediaType === StrapiMediaType.IMAGE ? (
        <Picture
            {...props.Media}
            aspectRatio={formatAspectRatio(props.Aspect_Ratio)}
            borderRadius={props.borderRadius}
            className={props.className}
            shouldParallax={shouldParallax}
        />
    ) : (
        <Text>Video</Text>
    );
};
